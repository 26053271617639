import React, { useEffect } from 'react';
import './App.scss';
import Header from '../Header/Header';
import mixpanel from 'mixpanel-browser'

function App() {

  mixpanel.init('a6ab06be7ed95ff4de57240da4ec47fe', {debug:true})
  mixpanel.track('pageload')

  useEffect(() => {
    document.querySelectorAll('.loadAnimation, .defaultSoftLoad').forEach((e) => { e.classList.add('loaded') })
  }, [])

  return (
    <div className="App">
      <div className="coloredBar defaultSoftLoad">
        <div className="color"></div>
      </div>
      <Header></Header>
      {/*
      <section id="startOfInformation" className="informationBox defaultSoftLoad">
        <div className="content">
          <h2>What's this website about exactly?&nbsp;🧐</h2>
          <p>
            Short answer? About me, and to showcase the stuff I do. <br />
            Long answer? I created this website because the title on my degree (<i>electromechanical</i> engineer) kind of botters me.
            Don't get me wrong, I love both the electrical and mechanical side of this field of study. 
            However, it only scratches the surface of my interests, skills, knowledge and experience.
            I chose for electromechanics mostly out of nececity of choice. I just as much enjoy electronics, software, design...
          </p>
          <p>
            In february of 2022, I obtained a <a href="http://google.com">master's degree</a> in engineering at Ghent University.
            As the degree states, I'm formally specialised in electromechanical engineering.
            <br />
            Having this mentioned on my degree botters me a little, because the choice for electromechanics was primarilly one of necessity of choice.
            <br />
            Don't get me wrong, I <i>love</i> electrical and mechanical engineering.
            However, I equally love and a lot of experience in computer sciences, electronics, product design&hellip;
          </p>
        </div>
      </section>
      */}
    </div>
  );
}

export default App;
