import React, { MutableRefObject, useRef } from 'react'
import './Header.scss'
import confetti from 'canvas-confetti'
import { BsFileEarmarkArrowDownFill } from 'react-icons/bs'
import { HiSparkles } from 'react-icons/hi'
import mixpanel from 'mixpanel-browser'

const cv = require('./../../assets/Evert_De_Spiegeleer_EN.pdf')

let travelling: boolean
let rotationAnglesToReach = { x: 0, y: 0 }
let rotationAngles = { x: 0, y: 0 }

export default function Header() {
    const mainEntry = useRef() as MutableRefObject<HTMLDivElement>
    const hand = useRef() as MutableRefObject<HTMLSpanElement>
    const contents = useRef() as MutableRefObject<HTMLDivElement>
    const confettiCanvas = useRef() as MutableRefObject<HTMLCanvasElement>
    const cvDownloadAnchor = useRef() as MutableRefObject<HTMLAnchorElement>

    let resetTimer = setTimeout(() => { })

    function handleMouseOver(event: React.MouseEvent) {
        clearTimeout(resetTimer)
        const mainEntryEl = mainEntry.current
        const cursorXFromCenter = (event.clientX - mainEntryEl.offsetWidth / 2) / mainEntryEl.offsetWidth
        const cursorYFromCenter = (event.clientY - mainEntryEl.offsetTop - mainEntryEl.offsetHeight / 2) / mainEntryEl.offsetHeight
        const newRotationAngles = {
            x: - cursorYFromCenter * 8,
            y: cursorXFromCenter * 8
        }
        setRotationAngleToReach(newRotationAngles)
        resetTimer = setTimeout(() => {
            setRotationAngleToReach({ x: 0, y: 0 })
        }, 4000)
    }

    function setRotationAngleToReach(angles: { x: number, y: number }) {
        rotationAnglesToReach = angles
        travelling = true
        lpf(16)
    }

    function lpf(t: number) {
        if (!travelling) return
        const distance = Math.sqrt((rotationAngles.x - rotationAnglesToReach.x) ** 2 + (rotationAngles.y - rotationAnglesToReach.y) ** 2)
        if (distance <= 0.25) {
            travelling = (false)
            return
        }

        rotationAngles = {
            x: rotationAngles.x + (rotationAnglesToReach.x - rotationAngles.x) * distance / 100,
            y: rotationAngles.y + (rotationAnglesToReach.y - rotationAngles.y) * distance / 100
        }

        contents.current.style.transform = `perspective(200px) rotateX(${rotationAngles.x}deg) rotateY(${rotationAngles.y}deg)`
        setTimeout(() => { lpf(t) }, t)
    }

    async function celebration() {
        confettiCanvas.current.style.display = 'block'

        const confettiExplosion = confetti.create(confettiCanvas.current, {
            resize: true,
            useWorker: true
        })

        const centerPos = [0.5, 0.25, 0.75]

        function explode(iteration: number) {
            return confettiExplosion({
                particleCount: 100,
                spread: 160,
                origin: {
                    x: Math.random() * 0.2 - 0.1 + centerPos[iteration],
                    y: Math.random() * 0.3 - 0.15 + 0.5
                },
                zIndex: -1,
                disableForReducedMotion: true
            })
        }

        return new Promise((resolve) => {
            const explosionPromises: Array<Promise<null> | null> = []
            explosionPromises.push(explode(0))
            let nrOfFires = 1;
            const interval = setInterval(() => {
                nrOfFires++
                explosionPromises.push(explode(nrOfFires - 1))
                if (nrOfFires === 3) {
                    clearInterval(interval)
                    /** Hide confetti canvas when all explosions are over */
                    Promise.all(explosionPromises).then((values) => {
                        confettiCanvas.current.style.display = 'none'
                    })
                    resolve(true)
                }
            }, 400)
        })
    }

    function handleDownloadCVClick() {
        (async () => {
            await celebration()
            cvDownloadAnchor.current.click()
            mixpanel.track('resume_download')
        })()
    }

    const handEmojis = ['✋', '🤟', '✌️', '🖖', '🚀']
    let currentEmoji = '✋'
    function handleHandClick() {
        let filteredEmojiArray = [...handEmojis]
        filteredEmojiArray.splice(filteredEmojiArray.indexOf(currentEmoji), 1)
        currentEmoji = filteredEmojiArray[Math.round(Math.random() * (filteredEmojiArray.length - 1))]
        hand.current.innerHTML = currentEmoji
    }

    return (
        <header onMouseOut={() => { clearTimeout(resetTimer); resetTimer = setTimeout(() => { setRotationAngleToReach({ x: 0, y: 0 }) }, 1000) }} onMouseMove={handleMouseOver} className="mainEntry" ref={mainEntry}>
            <canvas ref={confettiCanvas} className="confetti"></canvas>
            <div onClick={handleHandClick} ref={contents} className='contents'>
                <span ref={hand} className="hand loadAnimation">✋</span>
                <div className="text">
                    <h1 className='loadAnimation'>Hey there!</h1>
                    <h2 className='loadAnimation'>I'm Evert</h2>
                </div>
            </div>
            <div className="actions loadAnimation">
                <div onClick={() => { window.location.href = '#startOfInformation' }} className='button'>
                    <span className="icon"><HiSparkles /></span>
                    <span className='text'>Enter</span>
                </div>
                <div onClick={handleDownloadCVClick} className='button'>
                    <span className="icon"><BsFileEarmarkArrowDownFill /></span>
                    <span className='text'>Download my CV</span>
                </div>
                <a href={cv} ref={cvDownloadAnchor} target="_blank" rel='noreferrer' download='cvEvertDeSpiegeleer.pdf'> </a>
            </div>
        </header>
    )
}